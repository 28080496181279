import request from "api/restClient";

export const refreshToken = async () => {
  return await request({
    url: `refresh/access_token/`,
    method: "POST",
    data: {
      refresh_token: localStorage.getItem("refresh_token")
    }
  });
};
