import * as React from 'react';
export const loadingRef = React.createRef();


const setLoader = (loading) => {
  if (loading) {
    document.getElementById("preloader") && (document.getElementById("preloader").style.display = "block");
    document.getElementById("status") && (document.getElementById("status").style.display = "block");
  } else {
    document.getElementById("preloader") && (document.getElementById("preloader").style.display = "none");
    document.getElementById("status") && (document.getElementById("status").style.display = "none");
  }
};

const setTabBarLoader = (loading) => {
  if (loading) {
    loadingRef.current.continuousStart()
  } else {
    loadingRef.current.complete()
  }
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

function usFormat(amount) {
  return formatter.format(amount || 0)
}

function isSubscriptionPage() {
  return window.location.pathname.includes("subscription")
}

export {
  setLoader,
  setTabBarLoader,
  usFormat,
  isSubscriptionPage
};


