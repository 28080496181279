import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, setEmail, apiPassError } from "./actions";
import { login, find, requestPasswordChange, verifyPasswordChange, passwordChange, selectTenant as selectTenantApi } from 'api/v3'
import { toast } from "react-toastify";

function* loginUser({ payload: { user, history, actions } }) {
  try {
    const response = yield call(login, {
      email: user.email,
      password: user.password
    });

    yield put(loginSuccess("Select Company"));
    history.push(`/login/company/${response.uidb64}/${response.token}`);

  } catch ({ errors }) {
    if (errors?.non_field_errors)
      yield put(apiError(errors.non_field_errors[0]));
    else
      yield put(apiError("Something went wrong!"));
    if (errors) {
      actions.setErrors(errors)

    }
  }
}

function* verifyPasswordChangeToken({ payload: { history, data } }) {
  try {
    const response = yield call(verifyPasswordChange, data);

    if (response.success)
      yield put(loginSuccess(""));

  } catch ({ errors }) {
    yield put(apiError("Something went wrong!"));
    toast.error('Tokens are invalid')
    history.push("/login");
  }
}

function* resetPassword({ payload: { history, data, actions } }) {
  try {
    const response = yield call(passwordChange, data);

    if (response.success)
      yield put(loginSuccess(""));
    toast.success('Password is setup')
    actions.resetForm()
    history.push("/login");

  } catch ({ errors }) {
    if (errors.non_field_errors)
      yield put(apiError(errors.non_field_errors[0]));
    else
      yield put(apiError("Something went wrong!"));

    if (errors) {
      actions.setErrors(errors)
    }
  }
}

function* findUser({ payload: { user, actions } }) {
  try {
    const response = yield call(find, user)

    if (response.action == 'login') {
      yield put(loginSuccess("Please enter password"));
      actions.resetForm()

    }
    else if (response.action == 'set_password') {
      yield call(requestPasswordChange, { ...user, redirect_uri: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SUBDOMAIN}.${process.env.REACT_APP_HOST}/reset-password` })
      yield put(loginSuccess("Email has been sent to setup password"));

    }

    yield put(setEmail(user.email));

  } catch ({ errors }) {
    yield put(apiError("User not found"));
    if (errors) {
      actions.setErrors(errors)

    }
  }
}

function* requestPassChange({ payload: { user, actions, history } }) {
  try {
    yield call(requestPasswordChange, { ...user, redirect_uri: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_SUBDOMAIN}.${process.env.REACT_APP_HOST}/reset-password` })
    yield put(loginSuccess("Email has been sent for password change"));
    history.push('/login');

    yield put(setEmail(user.email));

  } catch ({ errors }) {
    if (errors.non_field_errors)
      yield put(apiPassError(errors.non_field_errors[0]));
    else
      yield put(apiPassError("Something went wrong!"));

    if (errors) {
      actions.setErrors(errors)

    }
  }
}

function* selectTenant({ payload: { company, actions, history } }) {
  try {
    const response = yield call(selectTenantApi, company)
    if (response.access_token) {
      localStorage.setItem("subdomain", company.tenant);
      localStorage.setItem("access_token", response.access_token);
      localStorage.setItem("refresh_token", response.refresh_token);
      yield put(setEmail(null));
      yield put(loginSuccess(""));
      toast.success("Loggged in Successfully!")

      history.push("/dashboard");
    }
    else {
      toast.error("Something went wrong!")
    }


  } catch ({ errors }) {
    if (errors.non_field_errors)
      yield put(apiPassError(errors.non_field_errors[0]));
    else
      yield put(apiPassError("Something went wrong!"));

    if (errors) {
      actions.setErrors(errors)

    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("subdomain");

    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery('FIND_USER', findUser);
  yield takeEvery('REQUEST_PASSWORD_CHANGE', requestPassChange);
  yield takeEvery('VERIFY_PASSWORD_CHANGE_TOKEN', verifyPasswordChangeToken);
  yield takeEvery('RESET_PASSWORD', resetPassword);
  yield takeEvery('SELECT_TENANT', selectTenant);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
